
export const environment = {
   production: true,
   apiURL: 'productionApi',
   sentryDsn: 'https://9cf4fcd5ccef4117b1e6c6c908f84774@sentry.io/5185278',
   stripeApiKey: 'undefined',
   stripePlans: {
     standard: '',
     premium: 'undefined',
   }
};
